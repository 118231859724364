import React, { useState } from 'react';
import { Link, useNavigate, useRevalidator } from '@remix-run/react';
import { useLoginModal } from '~/hooks/useLoginModal';
import { Button, GoogleIcon, Input, Modal } from '@cardo/ui';
import { IoChevronBackSharp, IoCloseSharp, IoMailSharp } from 'react-icons/io5';
import { generateAuthenticatedAppUrl } from '~/lib/utils';
import { ValidatedForm } from 'remix-validated-form';
import { withZod } from '@remix-validated-form/with-zod';
import { z } from 'zod';
import { HoneypotInputs } from 'remix-utils/honeypot/react';

export function LoginModal() {
  const { isOpen, closeModal, currentPage, navigateToPage, initialTitle } =
    useLoginModal();

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      containerClassName="z-50"
      className="mt-[55px] max-h-[calc(100vh-75px)] w-11/12 sm:w-fit"
    >
      <>
        <h3 className="text-lg font-medium leading-6 text-gray-900 flex items-start gap-3 justify-between">
          <div className="sm:h-5 sm:w-5">
            {currentPage !== 'initial' && (
              <button type="button" onClick={() => navigateToPage('initial')}>
                <span className="sr-only">Back</span>
                <IoChevronBackSharp className="flex-shrink-0 w-5 h-5 text-gray-400 hover:text-gray-500" />
              </button>
            )}
          </div>

          {currentPage === 'initial' && (
            <div className="max-w-96 text-center">
              {initialTitle ?? 'Sign In'}
            </div>
          )}
          {currentPage === 'login' && 'Log In with Email'}
          {currentPage === 'signup' && 'Create an Account'}

          <button type="button" onClick={closeModal}>
            <span className="sr-only">Close</span>
            <IoCloseSharp className="flex-shrink-0 w-6 h-6 text-gray-400 hover:text-gray-500" />
          </button>
        </h3>
        <div className="mt-4">
          {currentPage === 'initial' && <InitialPage />}
          {currentPage === 'login' && <LoginPage />}
          {currentPage === 'signup' && <SignupPage />}
        </div>
      </>
    </Modal>
  );
}

function InitialPage() {
  const { navigateToPage, actionAfterLogin } = useLoginModal();
  const [error, setError] = useState<string | null>(null);

  return (
    <div className="flex flex-col w-full gap-2 items-center py-4">
      <div
        onClick={() => navigateToPage('signup')}
        className="text-theme-blue-darkest no-underline w-full"
      >
        <Button
          type="submit"
          className="flex items-center gap-2.5 w-full text-center justify-center sm:min-w-96"
        >
          <IoMailSharp />
          Connect with Email
        </Button>
      </div>
      <div className="flex gap-2 items-center w-full sm:min-w-96">
        <div className="flex-grow h-px border-b border-gray-400" />
        <div className="text-gray-500 text-sm">or</div>
        <div className="flex-grow h-px border-b border-gray-400" />
      </div>
      <form
        method="post"
        action={generateAuthenticatedAppUrl('/auth/google')}
        className="no-underline w-full sm:min-w-96 flex flex-col space-y-3"
        onSubmit={async (e) => {
          e.preventDefault();
          try {
            // sets an actionAfterLogin cookie
            const res = await fetch(
              generateAuthenticatedAppUrl('/auth/after'),
              {
                method: 'POST',
                body: JSON.stringify({
                  returnTo: actionAfterLogin?.returnTo ?? location.href,
                  queryParams: actionAfterLogin?.queryParams,
                }),
                credentials: 'include',
              },
            );

            if (res.ok) {
              return (e.target as HTMLFormElement).submit();
            }

            throw new Error('Failed to log in with Google.');
          } catch (error) {
            console.error('Failed to save action:', error);
            setError('Failed to log in with Google.');
          }
        }}
      >
        <Button
          type="submit"
          className="flex items-center gap-2.5 w-full text-center justify-center"
        >
          <GoogleIcon />
          Connect with Google
        </Button>
        {error && <p className="mt-3 text-red-600">{error}</p>}
      </form>
    </div>
  );
}

export const loginValidator = withZod(
  z.object({
    username: z.string().min(1, {
      message: 'Username is required',
    }),
    password: z.string().min(1, { message: 'Password is required' }),
  }),
);

function LoginPage() {
  const { navigateToPage, closeModal } = useLoginModal();
  const revalidator = useRevalidator();

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (
    _data: object,
    e: React.FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();

    setLoading(true);
    setError(null);

    const formData = new FormData(e.target as HTMLFormElement);

    fetch(generateAuthenticatedAppUrl('/auth/login'), {
      method: 'POST',
      body: formData,
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok) {
          revalidator.revalidate();
          closeModal();
          return;
        }
        throw new Error('Login failed.');
      })
      .catch((error) => {
        if (error instanceof Error) {
          return setError(error.message);
        }
        setError('Login failed.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="flex h-full flex-col items-center py-4 gap-8">
      <ValidatedForm
        validator={loginValidator}
        onSubmit={handleSubmit}
        className="flex w-full flex-col space-y-3 sm:w-fit"
        id="login-form"
      >
        <HoneypotInputs label="Please leave this field blank" />
        <div className="flex flex-col">
          <Input
            name="username"
            label="Email/Username:"
            type="text"
            formId="login-form"
            autocomplete="username email"
          />
          <Input
            name="password"
            label="Password:"
            type="password"
            formId="login-form"
            autocomplete="current-password"
          />
        </div>
        <Button
          type="submit"
          disabled={loading}
          loading={loading}
          primary
          large
        >
          {loading ? 'Logging in...' : 'Log in'}
        </Button>
        {error && <p className="mt-3 text-red-600">{error}</p>}
      </ValidatedForm>
      <div className=" flex gap-3 text-xs">
        <div
          onClick={() => navigateToPage('signup')}
          className="text-theme-purple underline hover:no-underline cursor-pointer"
        >
          Sign up
        </div>
        <div>|</div>
        <Link to={generateAuthenticatedAppUrl('/forgotpassword')}>
          Forgot password?
        </Link>
      </div>
    </div>
  );
}

export const signupValidator = withZod(
  z.object({
    username: z.string().min(1, {
      message: 'Username is required',
    }),
    email: z.string().email({ message: 'Email is required' }),
    password: z
      .string()
      .min(8, { message: 'Password must be at least 8 characters' }),
  }),
);

function SignupPage() {
  const { navigateToPage, closeModal } = useLoginModal();
  const revalidator = useRevalidator();
  const navigate = useNavigate();

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (
    _data: object,
    e: React.FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();

    setLoading(true);
    setError(null);

    const formData = new FormData(e.target as HTMLFormElement);

    fetch(generateAuthenticatedAppUrl('/auth/signup'), {
      method: 'POST',
      body: formData,
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok) {
          revalidator.revalidate();
          closeModal();
          return;
        }

        if (response.status === 400) {
          navigate('/waitlist');
          closeModal();
        }

        throw new Error(
          'An unexpected error occurred. Failed to create account.',
        );
      })
      .catch((error) => {
        if (error instanceof Error) {
          return setError(error.message);
        }
        setError('An unexpected error occurred. Failed to create account.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="flex h-full flex-col items-center py-4 gap-8">
      <ValidatedForm
        validator={signupValidator}
        onSubmit={handleSubmit}
        className="flex w-full flex-col space-y-3 sm:w-fit"
        id="signup-form"
      >
        <HoneypotInputs label="Please leave this field blank" />
        <div className="flex flex-col">
          <Input
            name="username"
            label="Username:"
            type="text"
            formId="signup-form"
            autocomplete="username"
          />
          <Input
            name="email"
            label="Email:"
            type="email"
            formId="signup-form"
            autocomplete="email"
          />
          <Input
            name="password"
            label="Password:"
            type="password"
            formId="signup-form"
            autocomplete="new-password"
          />
        </div>
        <Button
          type="submit"
          disabled={loading}
          loading={loading}
          primary
          large
        >
          {loading ? 'Creating account...' : 'Sign up'}
        </Button>
        {error && <p className="mt-3 text-red-600 max-w-96">{error}</p>}
      </ValidatedForm>
      <div className="flex flex-col gap-2">
        <p className="max-w-96 text-center text-gray-500 text-sm">
          By continuing, you agree to our Terms of Service and confirm that you
          have read our Privacy Policy. When using Google Sign-In, Google's
          Privacy Policy and Terms of Service also apply.
        </p>
      </div>
      <div className="flex flex-col gap-1">
        <p className="text-xs">Already have an account?</p>
        <div
          onClick={() => navigateToPage('login')}
          className="w-full text-center text-xs text-theme-purple underline hover:no-underline cursor-pointer"
        >
          Login
        </div>
      </div>
    </div>
  );
}
